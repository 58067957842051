import { useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  InputGroup,
  Input,
  InputRightElement,
  Button,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import { Text, Fade } from '@convex/design_system/dist';
import { colors } from '../../theme';
import { SearchFormSlice, setSearchForm } from './searchFormSlice';
import { useAppDispatch } from '../../app/hooks';
import { isAlphanumericRegex } from '../../config/constants';
import { datadogRum } from '@datadog/browser-rum';

interface Props {
  iconButton?: boolean;
  reference?: string;
  toggleRefetch?: React.Dispatch<React.SetStateAction<boolean>>;
}

type Inputs = SearchFormSlice['searchForm'];

const SearchForm = ({ iconButton, reference, toggleRefetch }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [, setSearchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    resetField,
    watch,
    formState: { errors },
    setValue,
  } = useForm<Inputs>();

  useEffect(() => {
    setValue('reference', reference || '');
  }, [reference]);

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (location.pathname === '/') {
      navigate(`/search?q=${data.reference}`);
      datadogRum.addAction('search', {
        reference: data.reference,
      });
      return;
    }
    if (toggleRefetch) toggleRefetch((toggle) => !toggle);

    dispatch(setSearchForm({ searchForm: { ...data } }));
    setSearchParams({ q: data.reference });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl isInvalid={Boolean(errors.reference)}>
        <FormLabel fontSize={14} htmlFor="reference" data-testid="search-label">
          Search by reference
        </FormLabel>
        <Flex>
          <InputGroup>
            <Input
              type="text"
              id="reference"
              placeholder="Search by Convex, Eclipse or UMR reference"
              mr={2}
              defaultValue={reference}
              w="100%"
              isInvalid={Boolean(errors.reference)}
              isRequired
              bgColor={colors.dis.white}
              fontSize={14}
              data-testid="reference-field"
              {...register('reference', {
                required: true,
                pattern: {
                  value: isAlphanumericRegex,
                  message: 'Only letters and numbers are allowed.',
                },
              })}
            />
            {Boolean(watch('reference')) && (
              <InputRightElement width="4.5rem">
                <Fade in={true}>
                  <Button
                    size="sm"
                    variant="link"
                    color={colors.dis.blue}
                    fontWeight={400}
                    data-testid="reference-clear-button"
                    onClick={() => resetField('reference', { keepError: true })}
                  >
                    Clear
                  </Button>
                </Fade>
              </InputRightElement>
            )}
          </InputGroup>
          {iconButton ? (
            <IconButton
              aria-label="Search"
              colorScheme="blue"
              size="md"
              type="submit"
              icon={<SearchIcon />}
            />
          ) : (
            <Button
              colorScheme="blue"
              size="md"
              type="submit"
              data-testid="reference-search-button"
            >
              <Text px={4} fontWeight={400}>
                Search
              </Text>
            </Button>
          )}
        </Flex>
        <FormErrorMessage>
          <Flex direction="column" gap={1} maxW={330}>
            <Text>Special characters not allowed. Please enter a valid reference.</Text>
          </Flex>
        </FormErrorMessage>
      </FormControl>
    </form>
  );
};

export default SearchForm;
