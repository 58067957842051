import { combineReducers } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import { searchFormReducer } from '../features/search/searchFormSlice';

const createRootReducer = () =>
  combineReducers({
    auth: authReducer,
    searchForm: searchFormReducer,
  });

export default createRootReducer;
