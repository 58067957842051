import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { datadogRum } from '@datadog/browser-rum';
import { RootState } from '../../app/store';
import { User } from '../../domain/model/auth';
import { tokenStorageKey } from '../../config/auth';

export interface AuthSlice {
  isAuthenticated: boolean;
  isCheckingAuth: boolean;
  user?: User;
}

const initialState: AuthSlice = {
  isAuthenticated: false,
  isCheckingAuth: true,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
      state.isAuthenticated = true;
      state.isCheckingAuth = false;
      localStorage.setItem(tokenStorageKey, action.payload.idToken);
      const { username, email, firstName, lastName, department } = state.user;

      datadogRum.setUser({
        username,
        email,
        firstName,
        lastName,
        department,
      });
    },
    clearAuth: (state) => {
      state.user = undefined;
      state.isAuthenticated = false;
      state.isCheckingAuth = false;
      localStorage.removeItem(tokenStorageKey);
    },
  },
});

export const { setAuthUser, clearAuth } = authSlice.actions;
export const selectAuth = (state: RootState): AuthSlice => state.auth;
export default authSlice.reducer;
