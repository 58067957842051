import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selectAuth } from '../../features/auth/authSlice';
import {
  checkPreviousAuthSession,
  startAuthListener,
  removeAuthListener,
} from '../../features/auth/authListener';
import { User } from '../../domain/model/auth';
import { LDSingleKindContext } from 'launchdarkly-js-sdk-common';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { Maintenance } from '../../pages/maintenance/Maintenance';

import LoginPage from '../../pages/auth/LoginPage';
import { useReference } from '../../hooks/useReference';

interface Props {
  children: React.ReactElement;
}

const initialiseLaunchDarklyContext = (user: User | undefined): LDSingleKindContext => {
  if (!user) {
    // If anonymous is true then LD won't store the context
    return {
      kind: 'user',
      anonymous: true,
    };
  }
  return {
    kind: 'user',
    key: user.username,
    name: user.email,
  };
};

const Authentication: React.FC<Props> = ({ children }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const reference = useReference();
  const { user, isAuthenticated, isCheckingAuth } = useAppSelector(selectAuth);
  let LDuser = initialiseLaunchDarklyContext(user);

  const ldClient = useLDClient();
  const { enableMaintenance } = useFlags();

  useEffect(() => {
    startAuthListener(dispatch);
    return removeAuthListener;
  }, [dispatch]);

  useEffect(() => {
    LDuser = initialiseLaunchDarklyContext(user);
  }, [user]);

  useEffect(() => {
    (async () => {
      await ldClient?.identify(LDuser);
    })();
  }, [LDuser]);

  useEffect(() => {
    if (!isAuthenticated) {
      reference && localStorage.setItem('reference', reference);
      checkPreviousAuthSession(dispatch);
    }

    if (isAuthenticated) {
      const savedReference = localStorage.getItem('reference');
      if (savedReference) {
        navigate(`/search?q=${savedReference}`);
        localStorage.removeItem('reference');
      }
    }
  }, [dispatch, isAuthenticated, reference, navigate]);

  if (isCheckingAuth) return <div>Loading...</div>;
  if (enableMaintenance) return <Maintenance />;
  if (!isAuthenticated) return <LoginPage />;

  return children;
};

export default Authentication;
