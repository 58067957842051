import { Amplify, Auth } from 'aws-amplify';
import { env } from './env';

export const tokenStorageKey = 'dis-id-token';

const oauth = {
  region: env.cognito.region,
  userPoolId: env.cognito.userPoolId,
  userPoolWebClientId: env.cognito.appClientId,
  domain: env.cognito.authDomain,
  redirectSignIn: env.cognito.redirectSignIn,
  redirectSignOut: env.cognito.redirectSignOut,
  responseType: 'code',
};

export const initAuth = () => {
  Auth.configure({
    mandatorySignIn: true,
    region: env.cognito.region,
    userPoolId: env.cognito.userPoolId,
    userPoolWebClientId: env.cognito.appClientId,
  });

  Amplify.configure({ Auth: oauth });
  Auth.configure({ oauth });
};
