import { Navigate, Route, Routes } from 'react-router-dom';
import { INDEX, NOT_FOUND, SEARCH_RESULTS } from './paths';
import LandingPage from '../pages/landingPage/LandingPage';
import NotFound from '../pages/notFound/NotFound';
import SearchResultsPage from '../pages/searchResults/SearchResultsPage';

interface AppRoute {
  path: string;
  component: React.ReactElement;
}

const routes: AppRoute[] = [
  {
    path: INDEX,
    component: <LandingPage />,
  },
  {
    path: SEARCH_RESULTS,
    component: <SearchResultsPage />,
  },
  {
    path: NOT_FOUND,
    component: <NotFound />,
  },
];

export const AppRoutes = (): JSX.Element => {
  return (
    <Routes>
      {routes.map((route) => (
        <Route path={route.path} element={route.component} key={route.path} />
      ))}
      <Route path="*" element={<Navigate to={NOT_FOUND} replace />} />
    </Routes>
  );
};
