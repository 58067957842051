import { Box } from '@convex/design_system';

const NotFound = () => {
  return (
    <Box height="100%" alignItems="center" justifyContent="center" display="flex">
      An error has occurred. Please make sure you have the correct url.
    </Box>
  );
};

export default NotFound;
