import { ReactNode, useEffect, useState } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { env } from '../config/env';

type Provider = ({ children }: { children: ReactNode }) => JSX.Element;

export const LaunchDarklyProvider = ({ children }: { children: ReactNode }) => {
  const emptyComponent = () => <></>;
  const [LDProvider, setLDProvider] = useState<Provider>(() => emptyComponent);

  useEffect(() => {
    (async () => {
      const provider = await asyncWithLDProvider({
        clientSideID: env.launchDarklyClientId || '',
        deferInitialization: true,
        options: {
          bootstrap: 'localStorage',
          sendEvents: true,
          streaming: true,
        },
      });
      setLDProvider(() => provider);
    })();
  }, []);

  return <LDProvider>{children}</LDProvider>;
};
