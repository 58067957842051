import { Box, Heading, ListItem, Text, UnorderedList } from '@convex/design_system/dist';
import { colors } from '../../theme';
import SearchForm from '../search/SearchForm';

const LandingCard = () => {
  return (
    <Box
      pl={20}
      pr={20}
      py={10}
      mx={4}
      shadow="lg"
      rounded={10}
      width={600}
      maxW={700}
      bgColor={colors.dis.white}
    >
      <Box textAlign="center" pb={16} pt={4}>
        <Heading
          size="sm"
          textTransform="uppercase"
          fontWeight={700}
          color={colors.dis.grey}
          fontSize={18}
        >
          Search. Locate. Access
        </Heading>
        <Heading size="md" fontWeight={700} fontSize={40} mt={4}>
          Welcome to DocLinks
        </Heading>
      </Box>
      <Box w="100%" pb={5}>
        <SearchForm />
        <Box fontSize={14} color={colors.dis.darkGrey} mt={4} fontWeight={500}>
          <Text mb={1}>You can search by:</Text>
          <UnorderedList pl={2}>
            <ListItem>Convex reference e.g. A012345</ListItem>
            <ListItem>Eclipse reference e.g. EC123E45B678</ListItem>
            <ListItem>Unique Market reference e.g. B1234ABCDE123456</ListItem>
          </UnorderedList>
        </Box>
      </Box>
    </Box>
  );
};

export default LandingCard;
