import { Box, Heading, Text } from '@chakra-ui/react';
import { colors } from '../../theme';
import React from 'react';

export const Maintenance: React.FC = () => (
  <Box
    padding={'50px'}
    position={'fixed'}
    display={'flex'}
    alignItems={'center'}
    justifyContent={'center'}
    height={'100%'}
    width={'100%'}
  >
    <Box textAlign={'left'} lineHeight={'1.5'}>
      <Heading fontWeight="bold" size="md" mb={5}>
        We will be back soon!
      </Heading>
      <Text mb={5}>
        Thank you for your patience and understanding! We are currently undergoing maintenance.
        <br /> For further information, please see the Slack channel{' '}
        <a
          href="https://convexin.slack.com/archives/C05EAEBCY03"
          style={{ color: colors.dis.blue }}
        >
          #doclinks-support
        </a>
      </Text>
      <Text>&mdash; The DocLinks team</Text>
    </Box>
  </Box>
);
