import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import { store } from './app/store';
import Authentication from './common/layout/Authentication';
import { initAuth, initDatadogRum } from './config';
import AuthenticatedLayout from './common/layout/AuthenticatedLayout';
import { theme } from './theme';
import { LaunchDarklyProvider } from './providers/LaunchDarklyProvider';

initDatadogRum();
initAuth();

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <LaunchDarklyProvider>
      <Provider store={store}>
        <ChakraProvider theme={theme} resetCSS>
          <BrowserRouter>
            <Authentication>
              <AuthenticatedLayout />
            </Authentication>
          </BrowserRouter>
        </ChakraProvider>
      </Provider>
    </LaunchDarklyProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
