import { Auth, Hub } from 'aws-amplify';
import { HubCapsule } from '@aws-amplify/core/src/Hub';
import { AppDispatch } from '../../app/store';
import { clearAuth, setAuthUser } from './authSlice';
import { User } from '../../domain/model/auth';

const handleSignIn = async (dispatch: AppDispatch) => {
  const cognitoUser = await Auth.currentAuthenticatedUser().catch(() => handleSignOut(dispatch));

  const jwtToken = cognitoUser?.signInUserSession?.accessToken?.jwtToken;
  const idToken = cognitoUser?.signInUserSession?.idToken?.jwtToken;
  if (!jwtToken && !idToken) {
    return await handleSignOut(dispatch);
  }

  const user: User = {
    idToken,
    token: jwtToken,
    username: cognitoUser.username,
    email: cognitoUser.attributes.email,
    firstName: cognitoUser.attributes.given_name,
    lastName: cognitoUser.attributes.family_name,
    department: cognitoUser.attributes['custom:office'],
  };
  await dispatch(setAuthUser(user));
};

const handleSignOut = async (dispatch: AppDispatch) => {
  dispatch(clearAuth());
};

const handleAuthEvents = async (dispatch: AppDispatch, data: HubCapsule) => {
  switch (data.payload.event) {
    case 'signIn':
      await handleSignIn(dispatch);
      break;
    case 'signOut':
      await handleSignOut(dispatch);
      break;
    case 'signIn_failure':
      await handleSignOut(dispatch);
      break;
  }
};

let listener: (capsule: HubCapsule) => void;
let cancelAuthListener: () => void;
export const startAuthListener = (dispatch: AppDispatch) => {
  listener = (data: HubCapsule) => handleAuthEvents(dispatch, data);
  cancelAuthListener = Hub.listen('auth', listener);
};

export const removeAuthListener = () => cancelAuthListener && cancelAuthListener();

export const checkPreviousAuthSession = async (dispatch: AppDispatch) =>
  await handleSignIn(dispatch);
