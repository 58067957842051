import { extendTheme } from '@chakra-ui/react';
import { styles, fonts } from '@convex/design_system';

export const colors = {
  dis: {
    blue: '#3182CE',
    lightBlue: '#F7FAFD',
    grey: '#A0AEC0',
    darkGrey: '#69788c',
    white: '#FFFFFF',
  },
};

export const theme = extendTheme({
  styles,
  colors,
  fonts,
});
