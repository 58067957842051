import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { datadogRum } from '@datadog/browser-rum';
import { RootState } from '../../app/store';

export interface SearchFormSlice {
  searchForm: {
    reference: string;
  };
}

const initialState: SearchFormSlice = {
  searchForm: {
    reference: '',
  },
};

const searchFormSlice = createSlice({
  name: 'searchForm',
  initialState,
  reducers: {
    setSearchForm: (state, action: PayloadAction<SearchFormSlice>) => {
      state.searchForm = action.payload.searchForm;
      datadogRum.addAction('search', {
        reference: action.payload.searchForm.reference,
      });
    },
  },
});

export const { setSearchForm } = searchFormSlice.actions;
export const selectReference = (state: RootState): string => state.searchForm.searchForm.reference;

export const searchFormReducer = searchFormSlice.reducer;
