import { useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../app/hooks';
import { selectReference } from '../features/search/searchFormSlice';

export const useReference = () => {
  const searchTerm = useAppSelector(selectReference);
  const [searchParams] = useSearchParams();

  return searchParams.get('q') || searchTerm;
};
