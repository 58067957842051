export interface Env {
  apiUrl?: string;
  appEnvironment?: string;
  appVersion?: string;
  launchDarklyClientId?: string;
  dataDogRum: {
    appId?: string;
    clientToken?: string;
    site?: string;
    service?: string;
  };
  cognito: {
    region?: string;
    userPoolId?: string;
    appClientId?: string;
    authDomain?: string;
    redirectSignIn?: string;
    redirectSignOut?: string;
  };
}
const {
  REACT_APP_API_URL,
  REACT_APP_ENVIRONMENT,
  REACT_APP_VERSION,
  REACT_APP_DD_APP_ID,
  REACT_APP_DD_CLIENT_TOKEN,
  REACT_APP_DD_SITE,
  REACT_APP_DD_SERVICE,
  REACT_APP_COGNITO_REGION,
  REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
} = process.env;

export const env: Env = {
  apiUrl: REACT_APP_API_URL,
  appEnvironment: REACT_APP_ENVIRONMENT,
  appVersion: REACT_APP_VERSION,
  launchDarklyClientId: REACT_APP_LAUNCH_DARKLY_CLIENT_ID,
  dataDogRum: {
    appId: REACT_APP_DD_APP_ID,
    clientToken: REACT_APP_DD_CLIENT_TOKEN,
    site: REACT_APP_DD_SITE,
    service: REACT_APP_DD_SERVICE,
  },
  cognito: {
    region: REACT_APP_COGNITO_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
    appClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
    authDomain: process.env.REACT_APP_COGNITO_AUTH_DOMAIN,
    redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,
  },
};
